import { ee } from "../../services/earth-engine";
import { mergeProperties, retrieveExtremes, getDate, truncateDateNumberInMillis } from "../utils";
import {acquireFromDate as acquireFromDateLandSat} from "../../algorithms/satellite/landsat";
import { scoreCloudRatio } from "../imagery";
import * as Metadata from "../../common/metadata";
import { max } from "lodash";

const addGridPosition = (element) => {
  const image = ee.Image(element);

  return image.set({ [Metadata.GRID_POSITION]: image.get("MGRS_TILE") });
};

const sliceByRevisit = (collection, startingDate, days) => {
  const start = ee.Date(startingDate).update(null, null, null, 0, 0, 0);
  const end = start.advance(days, "day");

  return collection.filterDate(start, end);
};


const getImageDate = function (image){
  var id = image.idString;
  if(image.idString === undefined){
    id = image.get('system:id').getInfo();
    // console.error("Image without idString", image.getInfo(),"ID", id);
  } 
  var date = id.split("/");
  date = date[2].split("_"); //ee.String(date.get(2)).split("_");
  date  = date[0]; // date.get(0);
  let year = parseInt(date.substring(0,4)); 
  let monthIndex = parseInt(date.substring(4,6))-1;
  let day = parseInt(date.substring(6,8));
  var datetime = new Date(year,monthIndex,day);//ee.Date.parse('yyyyMMdd\'T\'HHmmss', date);

  return datetime;
} 

export class SentinelCloudCoverageCache{
  static collectionWithROICloud = null;

  calculateCloudCoverage = function (geometry){
    // date
    const startDate = '2013-01-01';  // TODO dynamize. Starting dates can be fetched from MissonCollections
    const endDate  = ee.Date(Date.now());
    // region
    const roi = geometry;
    // cloud
    const cloudProbThresh = 50;
    const cloudProbabilityBandName = "probability";
    const outputCloudBandName = "CLOUDS";

    try {
      const sentinelSRCollection = ee.ImageCollection('COPERNICUS/S2_HARMONIZED').filterBounds(roi).filterDate(startDate, endDate)
      const sentinelCloudCollection = ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY').filterBounds(roi).filterDate(startDate, endDate)

      // Generate a imageCollection by joining two Sentinel missions:
      const bandwiseInnerJoinCollection = sentinelSRCollection.combine(sentinelCloudCollection);

      // Calculate the percentage of cloud coverage
      const roiCloudProbabilityCollection = bandwiseInnerJoinCollection.map((image) => {
      const clippedImage = image.clip(roi);
      const roiArea = clippedImage.geometry().area()
      
      const newCloudPercentage = clippedImage.select(cloudProbabilityBandName);
      const likelyCloudMask = newCloudPercentage.gt(cloudProbThresh).rename(outputCloudBandName);
      const likelyCloudMaskArea = likelyCloudMask.multiply(ee.Image.pixelArea());
      const sumCloudValuesInCloudArea = likelyCloudMaskArea.reduceRegion({
        reducer: ee.Reducer.sum(),
        scale: 10,
        maxPixels: 1e9
      }).get(outputCloudBandName);

      const calculatedCloudPercentage = ee.Number(sumCloudValuesInCloudArea).divide(roiArea)
      return image.set({roiCloudPercentage: calculatedCloudPercentage})
        }
      )

      return roiCloudProbabilityCollection  

    } catch(e) { 
      console.log("Error", e);
      return ee.Number(0.01);
    }
  }
}

export const maskS2ImageMask = (img, mission) =>{
  const cloudBitMask = ee.Number(2).pow(10).int();
  const cirrusBitMask = ee.Number(2).pow(11).int();
  const qa = img.select(mission.bands.qa);
  let ma = qa.bitwiseAnd(cloudBitMask).eq(0).and(
          qa.bitwiseAnd(cirrusBitMask).eq(0));

  ma = ma.focal_min({kernel: ee.Kernel.circle({radius: 1}), iterations: 1});
  img = img.mask(ma);
  return img;
};

export const maskS2Clouds = (img, geometry, bandName, scale, startingDate, returnAsFloat = false) => {
  // Returns a number. The cloud pct for a single date
  let dtCloudMapping;
  let ret = 0.01;

  // get cloud mapping from cache
  try {
    dtCloudMapping = JSON.parse(window.sessionStorage.getItem("roiCloudCoveragePerDate")); // Safely parse JSON
  } catch (e) {
    console.error("ERROR: Failed to parse cloud coverage data from sessionStorage", e);
    return returnAsFloat ? ret : ee.Number(ret); // Return default if there's an error
  }

  const cloudPropertyKey = "cloud";

  if (startingDate !== undefined && dtCloudMapping) { // TODO why we have startingDates as NANs when debugging on chrome? 
    
    let startingDateInMillis = typeof startingDate === 'string'? 
      new Date(startingDate).getTime() : ee.Date(startingDate).millis() // diff types can be passed to this fn (not ideal)

    const mappingWithDateDiff = dtCloudMapping.map(el => (
      {...el, "diff": Math.abs(el.dt - startingDateInMillis)})
    );
    const matchingElement = mappingWithDateDiff.reduce(
      (prev, curr) => prev["diff"] < curr["diff"] ? prev : curr
    );

    if (matchingElement) {
      ret = matchingElement[cloudPropertyKey]
      return returnAsFloat ? ret : ee.Number(ret);
    }
  };
  
  // Default return if no match is found
  return returnAsFloat ? ret : ee.Number(ret);
};

export const acquireFromDate = (date, mission, geometry) => {

  if(mission.name.indexOf("LANDSAT")!==-1){
    return acquireFromDateLandSat(date, mission, geometry);
  }
  const slice = sliceByRevisit(
    ee.ImageCollection(mission.name).filterBounds(geometry),
    date,
    mission.cycle
  );
  //console.log("ACQUIRE FROM DATE - sentinel", mission);
  const mosaicked = slice.mosaic().clip(geometry);
  const bandName = "QA60";
  const scale = 60;
  const image = mosaicked.set(mergeProperties(slice));
  //console.log("MaskS2Cloud - for mosaic on ", date);
  const ratio = maskS2Clouds(image, geometry, bandName, scale, date);

  try{
   // console.log("CLOUDS-PERCENTAG-DEBUG",date,"image", image.getInfo(),"ratio" , ratio.getInfo());
    return image.set("CLOUDS", ratio);
  }catch(e){
    console.log("Error clouds", e);
    return image.set("CLOUDS", ee.Number(0));
  }
  
  //const ratio = scoreCloudRatio(maskedImage, bandName, geometry, scale);
  //return image.set("CLOUDS", ratio); 
};

// Computes a list of valid dates in the region to be retrieved with acquireFromDate.
export const processCollection = (mission, geometry) => {
  const query = ee.ImageCollection(mission.name).filterBounds(geometry);

  // Retrieve the globally extreme dates (earliest and latest)
  const global = retrieveExtremes(query);

  // Compute the grid position of each image and sort in ascending order
  const enhanced = query.map(addGridPosition).sort(Metadata.GRID_POSITION);

  // Retrieve the northeasternmost grid position within the specified bounds
  const northeasternPosition = ee
    .Image(enhanced.toList(1).get(0))
    .get(Metadata.GRID_POSITION);

  // Keep images in the slice where the satellite passed first (northeast)
  const filtered = enhanced.filter(
    ee.Filter.eq(Metadata.GRID_POSITION, northeasternPosition)
  );

  // Retrieve the extremes in the northeastern position
  const northeastern = retrieveExtremes(filtered);

  // Compute the difference in days between the earliest image in the
  // northeastern position and the globally earliest image
  const difference = northeastern.earliest
    .difference(global.earliest, "day")
    .abs();
  const remainder = ee.Number(difference).mod(mission.cycle);

  // The amount of days we need to go back is given by the reverse of the
  // difference, in terms of the duration of an orbit
  const step = ee.Number(mission.cycle).subtract(remainder);

  // Compute the date of the earliest possible image in the northeastern
  // position (whether or not it exists) by going back in time
  const earliestDate = global.earliest.advance(step.multiply(-1), "day"); // 1.21 gigawatts!

  // Compute the amount of complete orbital cycles between the earliest and
  // latest possible dates of the images in the northeastern position (whether
  // or not they exist)
  const completeCycles = ee
    .Number(earliestDate.difference(global.latest, "day").abs())
    .divide(mission.cycle)
    .ceil();

  // Generate slices of 5 (0, 5, 10, 15, ...), one for each complete cycle
  const additions = ee.List.sequence(0, null, mission.cycle, completeCycles);

  // Transform each slice into an empty image. If the slice contains at least
  // one image, we add metadata related to the correspondent orbital cycle,
  // to allow for filtering later
  const carriers = additions.map((increment) => {
    const startingDate = earliestDate.advance(increment, "day");
    const collection = sliceByRevisit(query, startingDate, mission.cycle);

    const empty = ee.Algorithms.IsEqual(collection.size(), 0);
    const properties = ee.Algorithms.If(empty, {}, mergeProperties(collection));

    return ee.Image(0).set(properties);
  });

  // Keep only the images whose combined geometries contain the AOI
  const valid = ee.ImageCollection.fromImages(carriers).filter(
    ee.Filter.contains(Metadata.FOOTPRINT, geometry)
  );

  // For now only the date of the slice is important.
  return ee.List(valid.toList(valid.size()).map(getDate));
};

export const generateCloudMap = (dates, mission, geometry) => {
  const cloudList = ee.List(dates).map((date) => {
    const image = ee.Image(acquireFromDate(date, mission, geometry));
    return image.get("CLOUDS");
  });

  return cloudList;
};

// TODO dedup code
const queryAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);
  // Format Data as ISO standart formating and and UTC
  const datesQuery = query.map((date) => ee.Date(date).format(null, 'UTC'));
  const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

  return ee.Dictionary.fromLists(datesQuery, cloudQuery);
};

const getAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);
  // Format Data as ISO standart formating and and UTC
  const datesQuery = query.map((date) => ee.Date(date).format(null, 'UTC'));
  const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

  return ee.Dictionary.fromLists(datesQuery, cloudQuery);
};

const acquire = (mission) => (date, geometry) => {
  if(window.location.href.indexOf("bathymetry") !== -1) return false;
  console.log("PARAMS", date, mission, geometry);
  return acquireFromDate(date, mission, geometry);
};

const format = (properties) => {
  return properties["system:time_start"] + " -- " + properties["cloud"];
};

export default {
  queryAvailable,
  getAvailable,
  acquire,
  format,
};
